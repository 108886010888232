import { FC } from "react";
import Head from "next/head";
import { ProjectConfig, PageMeta } from "@shared/types";
import { GLOBAL_CONFIG } from "@shared/constants";

interface Props {
  baseUrl: string;
  pageMeta?: PageMeta;
  favicon: string;
  assets: string;
  path: string;
}

const DocumentHead: FC<Props> = ({
  baseUrl,
  pageMeta,
  favicon,
  assets,
  path,
}: Props) => {
  const title = pageMeta?.title ?? "Insert Title Here",
    description = pageMeta?.description ?? "Insert Description Here",
    image = pageMeta?.image ?? "";
  const { SITE_NAME, TWITTER } = GLOBAL_CONFIG as ProjectConfig;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="icon" href={favicon} />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${baseUrl}${path}`} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={SITE_NAME} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${image}`} />

      {/* <!-- Twitter --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={TWITTER} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={`${image}`} />
    </Head>
  );
};

export default DocumentHead;
